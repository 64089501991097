function init() {
    $.ncsFileLinks();
    $.fn.ncsExternalLinks();
    $('form').ncsForms();
    $('.no-touch body').ncsTopLink();
    $('.biglink').ncsBigLink();

    // Tooltips & Popovers
    // ---------------------------------------- //
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();

    // Fancybox
    // ---------------------------------------- //
    $('.fancybox').fancybox();

    $('.fancybox-media').fancybox({
        openEffect: 'none',
        closeEffect: 'none',
        helpers: {
            media: {}
        }
    });

    $(".fancybox-form").fancybox({
        type: 'iframe',
        padding: 15,
        afterClose: function () {
            parent.location.reload(true);
        }
    });

    $('.modal-option').click(function (evt) {
        evt.preventDefault();
        var url = this.href;

        if ($(window).width() > 767) {
            $.fancybox.open({
                type: 'iframe',
                minWidth: 700,
                maWidth: 900,
                href: url + '?modal=1'
            });
        } else {
            window.location = url + '?fullscreen=1'
        }
    });
}
